<template>
    <div class="news-list">
         <div v-for="item in userlist" :key="item.id">
             <news-list-item :user="item" :userId="userId"></news-list-item>
         </div>
    </div>

</template>

<script>

    import NewsListItem from "./NewsListItem";

    export default {
        name: "NewsList",
        components: {NewsListItem},
        props:{
            userlist:{
                type:Array
            }
        },
        data(){
            return{
                userId:null
            }
        },
        created() {
            this.userId=window.sessionStorage.getItem("userId")
        }

    }
</script>

<style scoped>

</style>