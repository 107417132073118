<template>
    <div class="news-list-item">
        <div class="item-img">
            <img src="../../../assets/img/message.png"/>
        </div>

        <div class="item-name">
            <div class="group-space-between" style="margin-bottom:5px;">
                <span>消息通知</span>  <!--时间-->
                <a v-if="user.send_time!=null" >{{user.send_time|dateFormat}}</a>
            </div>
            <a>{{user.message}}</a>
        </div>
    </div>
</template>

<script>
    import {clearMessage} from "network/new"
    export default {
        name: "NewsListItem",
        components:{
            /*Chat*/
        },
        data(){
            return{
                userlist:{},
                chatnewslist:[],
            }
        },
        props:{
            user:{
                type:[Object,String]
            },
            /*发消息的用户*/
            user_id:{
                type:[Number,String]
            },
            /*自己*/
            userId:{
                type:[Number,String]
            },

        },created() {
            this.clearMessage()

        },methods:{
            clearMessage(){
                clearMessage()
            },
            /**/
            btnchat(){
                this.$router.push('/Chat/'+this.user_id)
            },

            btnsend(){

            }

        }
    }
</script>

<style scoped>
    .news-list-item{
        display: flex;
        padding: 10px 10px;
    }
    .item-img img{
        width:54px;
        border-radius: 50%;
        margin-right: 10px;
    }
    .item-name{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-bottom: 1px solid rgba(180,180,180,.3);
    }
    .item-name span{
        font-size: 14px;
    }
    .item-name a{
        font-size: 12px;
        margin-top: 5px;
    }
    .chat{
        position: fixed;
        height: 100vh;
        width: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        z-index: 20;
        transition: all 0.5s;
    }
    
</style>